<template>
  <!-- 页面name：疾病防控上报 -->
  <div class="contain">
    <div class="form-header-btn">
      <el-page-header @back="goBack" :content="'疾病防控'">
        <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
    </div>
    <div v-if="lookSubStatus == '0' && caseNum > 3" class="head-tips">
      <!-- <el-button :plain="true" duration="0" @click="open3">警告</el-button> -->
      <img src="../../../assets/img/ShapeCopy2.png" alt="" class="tips-icon" />
      <span>
        您所在园所本月已上报传染病<span style="color: #c6000b">{{
          caseNum
        }}</span
        >起，请注意传染病预防工作。
      </span>
    </div>
    <div
      v-if="lookSubStatus == '1' || lookSubStatus == '2'"
      class="con-header-little"
    >
      <i class="el-icon-caret-right" style="margin-right: 0.52083vw"></i>
      <span>基本信息</span>
      <el-divider></el-divider>
    </div>
    <div class="form">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="right"
        :label-width="labelWidth"
        class="demo-ruleForm"
      >
        <el-form-item label="所属分公司:" prop="companyLabel">
          <el-select
            v-model="ruleForm.companyLabel"
            placeholder="请选择"
            :disabled="schoolListStatus == 1 ? true : ruleFormStatus"
          >
            <el-option
              v-for="item in moduleCompanyOptions"
              :key="item.label"
              :label="item.name"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="患病园校:" prop="campusLabel">
          <el-select
            v-model="ruleForm.campusLabel"
            placeholder="请选择"
            :disabled="schoolListStatus == 1 ? true : ruleFormStatus"
          >
            <el-option
              v-for="item in moduleParkOptions"
              :key="item.label"
              :label="item.name"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="患病班级:" prop="classLabel">
          <el-select
            v-model="ruleForm.classLabel"
            placeholder="请选择"
            :disabled="ruleFormStatus"
          >
            <el-option
              v-for="item in moduleClassOptions"
              :key="item.label"
              :label="item.name"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="传染病名称:" prop="contagionName">
          <el-select
            v-model="ruleForm.contagionName"
            placeholder="请选择传染病名称"
            :disabled="ruleFormStatus"
          >
            <el-option
              v-for="item in dictList.contagion_name"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="补充说明:"
          prop="moreExplain"
          v-if="ruleForm.contagionName == 0 && ruleForm.contagionName != ''"
        >
          <el-input
            v-model="ruleForm.moreExplain"
            type="textarea"
            class="elInput"
            placeholder="请输入补充说明"
            clearable
            :disabled="ruleFormStatus"
            maxlength="499"
          ></el-input>
        </el-form-item>

        <el-form-item label="患病人数:" prop="sickNum">
          <el-input
            v-input-number-two
            @keyup.native="getInputChange(ruleForm.sickNum, 'sickNum')"
            v-model="ruleForm.sickNum"
            class="elInput"
            :maxlength="9"
            placeholder="请输入患病人数"
            clearable
            :disabled="ruleFormStatus"
          ></el-input>
        </el-form-item>
        <el-form-item label="严重程度:" prop="level">
          <el-radio-group
            v-model="ruleForm.level"
            @change="changeContagionLevel"
            :disabled="ruleFormStatus"
          >
            <el-radio
              v-for="(item, index) in dictList.contagion_level"
              :key="index"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上报人:" prop="reportName">
          <el-input
            v-model="ruleForm.reportName"
            class="elInput"
            maxlength="40"
            placeholder="请输入上报人"
            clearable
            :disabled="schoolListStatus == 1 ? true : ruleFormStatus"
          ></el-input>
        </el-form-item>
        <el-form-item label="原因:" prop="reason">
          <el-input
            v-model="ruleForm.reason"
            type="textarea"
            class="elInput"
            placeholder="请输入原因"
            clearable
            :disabled="ruleFormStatus"
            maxlength="499"
          ></el-input>
        </el-form-item>
        <el-form-item label="采取措施:" prop="measure">
          <!-- 上报 -->
          <div v-if="lookSubStatus == '0' || lookSubStatus == '3'">
            <el-input
              v-model="ruleForm.measure"
              type="textarea"
              class="elInput"
              placeholder="请输入采取措施"
              clearable
              maxlength="499"
            ></el-input>
          </div>
          <!-- 审核 -->
          <div v-else-if="lookSubStatus == '1'">
            <el-input
              v-model="ruleForm.measure"
              type="textarea"
              class="elInput"
              placeholder="请输入采取措施"
              clearable
              :disabled="true"
              maxlength="499"
            ></el-input>
            <!-- <div class="mea-con-tablehead middle-content">
              <div class="tablehead-times con-fonts">
                {{ ruleForm.measureTime }}
              </div>
              <div class="tablehead-idea con-fonts">
                {{ ruleForm.measure }}
              </div>
            </div> -->
          </div>
          <!-- 查看 -->
          <div
            v-else-if="lookSubStatus == '2'"
            class="measure-contain measure-mar"
          >
            <div class="mea-con-tablehead tablehead-backg">
              <div class="tablehead-times tablehead-times-head">时间</div>
              <div class="tablehead-idea tablehead-times-head">采取措施</div>
            </div>

            <div>
              <div
                v-if="ruleForm.historyList && ruleForm.historyList.length > 0"
              >
                <div
                  class="mea-con-tablehead middle-content"
                  v-for="(item, index) in ruleForm.historyList"
                  :key="index"
                >
                  <div class="tablehead-times con-fonts">
                    {{ item.measureTime }}
                  </div>
                  <div class="tablehead-idea con-fonts">{{ item.measure }}</div>
                </div>
              </div>
              <div v-else class="mea-con-tablehead middle-content">
                <div class="tablehead-times con-fonts">
                  {{ ruleForm.measureTime }}
                </div>
                <div class="tablehead-idea con-fonts">
                  {{ ruleForm.measure }}
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="附件:" prop="fileList">
          <div v-if="lookSubStatus == '0' || lookSubStatus == '3'">
            <el-upload
              action="/webForm/dataDirectory/data-attachment/uploadFile"
              :headers="uploadHeader"
              :on-change="handleChange"
              :on-success="handleSuccess"
              :on-remove="handleRemove"
              :before-upload="beforeAvatarUpload"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <!-- <div slot="tip" class="el-upload__tip">请上传文件</div> -->
            </el-upload>
          </div>
          <div v-else>
            <div
              class="files-look"
              v-for="(item, index) in ruleForm.fileList"
              :key="index"
            >
              <img
                class="file-smallimg"
                src="../../../assets/img/attach.png"
                alt=""
              />
              <span class="con-fonts">
                {{ item.name }}
              </span>
              <span class="files-button">
                <el-button type="text" @click="showFile(item)">查看</el-button>
                <el-button type="text" @click="downLoadFile(item.id)"
                  >下载</el-button
                >
              </span>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div v-if="lookSubStatus == '0'" class="remind">
        <div
          class="remind-title"
          :style="{ width: 'calc(' + labelWidth + ' - 1.04167vw)' }"
        >
          提醒:
        </div>
        <div class="remind-content">
          <ul>
            <li>1.上报时间传染病发生两个小时之内</li>
            <li>2.传染病控制后的跟进返园时间上报（返园前一天）</li>
            <li>3.发生传染病的班级放假期间如有异常请及时上报</li>
          </ul>
        </div>
      </div>
      <div v-if="lookSubStatus == '0' || lookSubStatus == '3'" class="form-btn">
        <el-button class="cancel" @click="onCancel">取消</el-button>
        <el-button class="submit" @click="onSubmit('ruleForm')">提交</el-button>
      </div>
    </div>

    <!-- v-if="ruleForm.historyList && ruleForm.historyList.length > 0" -->
    <!-- 意见反馈 -->
    <div
      v-if="
        ruleForm.historyList &&
        ruleForm.historyList.length > 0 &&
        lookSubStatus == '2'
      "
    >
      <div class="con-header-little">
        <i class="el-icon-caret-right" style="margin-right: 0.52083vw"></i>
        <span>意见反馈</span>
        <el-divider></el-divider>
      </div>
      <div class="form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="right"
          :label-width="labelWidth"
          class="demo-ruleForm"
        >
          <el-form-item label="处理意见:" prop="fileList">
            <div class="measure-contain">
              <div class="mea-con-tablehead tablehead-backg">
                <div class="tablehead-times tablehead-times-head">时间</div>
                <div class="tablehead-idea tablehead-times-head">处理意见</div>
              </div>
              <div
                class="mea-con-tablehead middle-content"
                v-for="(item, index) in ruleForm.historyList"
                :key="index"
              >
                <div class="tablehead-times con-fonts">
                  {{ item.commentTime }}
                </div>
                <div class="tablehead-idea con-fonts">{{ item.comment }}</div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div v-if="lookSubStatus == '1'">
      <div class="con-header-little">
        <i class="el-icon-caret-right" style="margin-right: 0.52083vw"></i>
        <span>意见反馈</span>
        <el-divider></el-divider>
      </div>
      <div class="form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="right"
          :label-width="labelWidth"
          class="demo-ruleForm"
        >
          <el-form-item label="处理意见:">
            <div>
              <el-input
                v-model="ruleForm.comment"
                type="textarea"
                class="elInput"
                placeholder="请输入处理意见"
                clearable
                maxlength="499"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div v-if="lookSubStatus == '2'">
      <div class="con-header-little">
        <i class="el-icon-caret-right" style="margin-right: 0.52083vw"></i>
        <span>结束情况</span>
        <el-divider></el-divider>
      </div>
      <div class="form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="right"
          :label-width="labelWidth"
          class="demo-ruleForm"
        >
          <el-form-item label="结束时间:" prop="feedbackTime">
            <div>
              <el-input
                v-model="ruleForm.feedbackTime"
                class="elInput"
                placeholder="请选择结束时间"
                clearable
                :disabled="ruleFormStatus"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="目前情况:" prop="feedbackCondition">
            <div>
              <el-input
                v-model="ruleForm.feedbackCondition"
                type="textarea"
                class="elInput"
                placeholder="请输入目前情况"
                clearable
                :disabled="ruleFormStatus"
                maxlength="499"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div v-if="lookSubStatus == '1'" class="form-btn-dt">
      <el-button class="goback" @click="onCommentStatus('ruleForm', 1)"
        >打回</el-button
      >
      <el-button class="cancel" @click="onCancel">取消</el-button>
      <el-button class="submit" @click="onCommentStatus('ruleForm', 0)"
        >通过</el-button
      >
    </div>
    <onlinePreview
      :onlinePreviewVisible="onlinePreviewVisible"
      :type="fileAttachment.previewType"
      @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
      :url="fileAttachment.url"
      :title="
        (fileAttachment.attachment_name || '') +
        '.' +
        (fileAttachment.attachment_type || '')
      "
    />
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import downloadFile from "@/utils/downloadFile";
import onlinePreview from "@/components/onlinePreview/index";
import { mapGetters, mapState } from "vuex";
import { testFun } from "@/utils/validate";
export default {
  data() {
    return {
      fileList: [],
      ruleForm: {
        companyLabel: "", //分公司
        campusLabel: "", //园校
        classLabel: "", //班级
        contagionName: "", //传染病名称
        moreExplain: "", //补充说明
        sickNum: "", //患病人数
        reportName: "", //上报人
        level: "", //严重程度
        reason: "", //原因
        measure: "", //采取措施
        measureTime: "", //采取措施的时间
        fileList: [], //文件列表
        historyList: [], //历史记录
        comment: "",
        type: "2", //上报组织
        feedbackTime: "",
        feedbackCondition: "",
      },
      rules: {
        companyLabel: [
          { required: true, message: "请选择公司", trigger: "blur" },
        ],
        campusLabel: [
          { required: true, message: "请选择园校", trigger: "blur" },
        ],
        classLabel: [
          { required: true, message: "请选择班级", trigger: "blur" },
        ],
        contagionName: [
          { required: true, message: "请选择传染病", trigger: "blur" },
        ],
        moreExplain: [
          { required: true, message: "请输入补充说明", trigger: "blur" },
        ],
        sickNum: [
          { required: true, message: "请输入患病人数", trigger: "blur" },
        ],
        reportName: [
          { required: true, message: "请输入上报人", trigger: "blur" },
        ],
        level: [{ required: true, message: "请输入严重程度", trigger: "blur" }],
        reason: [{ required: true, message: "请输入原因", trigger: "blur" }],
        measure: [{ required: true, message: "请输入措施", trigger: "blur" }],
        // fileList: [{ required: true, message: "请添加附件", trigger: "blur" }],
        comment: [
          { required: true, message: "请填写处理意见", trigger: "blur" },
        ],
        feedbackTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        feedbackCondition: [
          { required: true, message: "请输入目前情况", trigger: "blur" },
        ],
      },
      moduleCompanyOptions: [],
      moduleParkOptions: [],
      moduleClassOptions: [],
      contagionLevelList: [], //严重程度
      contagionNameList: [], //传染病名称
      positionList: [],
      dictList: {
        contagion_level: [],
        contagion_name: [],
      }, //查询字典值
      caseNum: "",
      lookId: "",
      lookSubStatus: "", //为新增还是编辑
      uploadHeader: { token: this.getToken() },
      fileAttachment: {},
      onlinePreviewVisible: false,
      ruleFormStatus: false,
      schoolListStatus: 0,
      labelWidth: "150px",
      submitCount: 0,
    };
  },

  components: {
    onlinePreview,
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },

  mounted() {
    this.lookId = this.$route.query.lookId;
    this.lookSubStatus = this.$route.query.lookSubStatus;
    if (this.$route.query.lookId) {
      this.getDiseaselookover();
    }
    if (
      (this.$route.query.lookId && this.$route.query.lookSubStatus == 2) ||
      this.$route.query.lookSubStatus == 1
    ) {
      this.ruleFormStatus = true;
    }

    if (this.userInfo.userName) {
      this.ruleForm.reportName = this.userInfo.userName;
      this.schoolListStatus = 1;
    }

    if (this.userInfo.schoolList.length > 0) {
      this.ruleForm.companyLabel = this.userInfo.schoolList[0].companyLabel; //分公司
      this.ruleForm.campusLabel = this.userInfo.schoolList[0].schoolLabel; //园校
      this.schoolListStatus = 1;
    }

    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "110px";
      } else {
        this.labelWidth = (150 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "110px";
    } else {
      this.labelWidth = (150 / 1920) * window.innerWidth + "px";
    }

    this.getCompanyDeptList();
    this.getSchoolList();
    this.getClassInfoList();
    this.queryDict();
    this.getCountByLoginUser();
  },

  methods: {
    getToken,
    //上报数据回显
    getDiseaselookover() {
      let params = {
        id: this.lookId,
      };
      this.$api.getDiseaselookover(params).then((res) => {
        if (res.data.code == 0) {
          let ruleFormobj = res.data.data;
          this.fileList = res.data.data.fileList;
          this.ruleForm.historyList = res.data.data.historyList;
          // this.ruleForm.level = res.data.data.level.value;
          // this.ruleForm.contagionName = res.data.data.contagionName.value;

          for (const key in this.ruleForm) {
            this.ruleForm[key] = ruleFormobj[key];
            console.log(ruleFormobj[key]);
            if (
              Object.prototype.toString.call(ruleFormobj[key]) ==
              "[object Object]"
            ) {
              if (ruleFormobj[key].value) {
                this.ruleForm[key] = ruleFormobj[key].value;
              }
            }
          }

          this.getSchoolList(this.ruleForm.companyLabel);
          this.getClassInfoList(this.ruleForm.campusLabel);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //字典查询
    queryDict() {
      var dict = Object.keys(this.dictList);
      let requests = dict.map(
        (item) =>
          new Promise((resolve, reject) => {
            this.$api
              .getDictionary({ type: item })
              .then((res) => {
                res.data.fieldName = item;
                resolve(res.data);
              })
              .catch(() => {
                resolve([]);
              });
          })
      );
      Promise.all(requests).then((res) => {
        res.forEach((item) => {
          this.dictList[item.fieldName] = item.data;
        });
      });
    },

    //查询分公司
    getCompanyDeptList() {
      let params = {
        type: "NODE_ORG",
      };
      this.$api.getCompanyDept(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleCompanyOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询园校
    getSchoolList(valCompany) {
      let params = {
        parent: valCompany,
        type: "NODE_SCHOOL",
      };
      this.$api.getCompanyDept(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleParkOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询分公司change事件
    // handleCompany(val) {
    //   if (val) {
    //     this.disabledPark = false;
    //     this.getSchoolList(val);
    //   }
    // },

    //查询园校change事件
    // handlePark(val) {
    //   if (val) {
    //     this.disabledClass = false;
    //     this.getClassInfoList(val);
    //   }
    // },

    //查询班级
    getClassInfoList(valPark) {
      let params = {
        parentLabel: valPark,
      };
      this.$api.getClassInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleClassOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    getInputChange(value, type) {
      let resultValue = value.replace(/[^\w\.\/]/gi, "");
      this.ruleForm[type] = resultValue;
    },

    //上传文件
    handleSuccess(res, file, fileList) {
      console.log(res, file, fileList, "aa");
      this.uploading = false;
      if (res.code != 0) {
        /*上传失败 移除列表*/
        this.$message.error(res.msg);
        fileList.forEach((item, index) => {
          if (item.uid == file.uid) {
            fileList.splice(index, 1);
          }
        });
        return;
      }
      this.ruleForm.fileList = [];
      // console.log(this.ruleForm.fileList, "shangcccccccccwwwwwwwwwwwyyyyyyyyy");
      this.ruleForm.fileList.push({
        name: file.name.split(".")[0],
        fileType: file.name.split(".")[file.name.split(".").length - 1],
        uid: file.uid,
        type: "1",
        size: file.size,
        url: res.data,
      });
    },

    //删除上传文件
    handleRemove(file, fileList) {
      let uid = file.uid || file.id;
      this.ruleForm.fileList = [];
      this.fileList.forEach((item, index) => {
        if (item.uid == uid || item.id == uid) {
          this.fileList.splice(index, 1);
        }
      });
    },

    //上传前检查文件格式
    beforeAvatarUpload(file) {
      if (this.checkFileType(file.name)) {
        return file;
      }
      return false;
    },

    //检查文件格式
    checkFileType(name) {
      let regx = null;
      let type = name.split(".")[name.split(".").length - 1];
      // let types = ['jpeg', 'jpg', 'png', 'mp4'];
      // const isImage = types.includes(type);
      // Word、Excel、PDF、图片、视频文件上传!
      regx =
        /docx|DOCX|doc|DOC|dot|DOT|xlsx|XLSX|xls|XLS|xlt|XLT|pdf|PDF|jpeg|JPEG|jpg|PNG|png|PNG|mp4|MP4/;

      // if (!regx.test(type) && !isImage) {
      if (!regx.test(type)) {
        this.$message.error(
          "文件格式有误,只支持Word、Excel、PDF、图片、视频文件上传!"
        );
        return false;
      }
      return true;
    },

    //查看文件
    showFile(item) {
      if (item.pdfPath) {
        let url = "/contagion/attachment/downloadFile";
        let data = {
          id: item.id,
          type: 2,
          flag: 1,
        };
        let type = "get";
        this.onlinePreviewVisible = true;
        this.fileAttachment = {
          previewType: "pdf",
          url: item.pdfPath,
          attachment_name: item.attachment_name,
          attachment_type: item.attachment_type,
        };
      } else {
        this.$message.error("暂不支持在线预览，请下载后查看");
        return;
      }
    },

    //下载文件
    downLoadFile(id) {
      //下载文件
      let url = "/contagion/attachment/downloadFile";
      let data = {
        id: id,
        type: 1,
        flag: 0,
      };
      let type = "get";
      this.tableLoading = true;
      downloadFile({ url, data, type }).then(() => {
        this.tableLoading = false;
      });
    },

    //只可上传一个文件
    handleChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 这一步，是 展示最后一次选择的csv文件
      }
    },

    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },

    //传染病严重程度
    changeContagionLevel(value) {},

    //获取园所上报传染病例
    getCountByLoginUser() {
      let params = {
        campusLabel: this.ruleForm.campusLabel,
      };

      this.$api.getCountByLoginUser(params).then((res) => {
        if (res.data.code == 0) {
          this.caseNum = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //返回上一页
    goBack() {
      this.$router.go(-1);
    },

    //取消
    onCancel() {
      this.goBack();
    },

    //处理状态，打回还是通过
    //打回\通过状态
    onCommentStatus(ruleForm, comStatus) {
      // console.log(comStatus, "tongguodahui");
      if (comStatus == 1 && !this.ruleForm.comment) {
        this.$message.error("请填写处理意见");
        return;
      }
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let params = {
            contagionId: this.lookId,
            status: comStatus,
            comment: this.ruleForm.comment,
          };
          this.$api.getCommentSave(params).then((res) => {
            if (res.data.code == 0) {
              // this.$message.success(res.data.msg);
              this.goBack();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          this.$message.error("请填写处理意见");
          return false;
        }
      });
    },

    //提交
    onSubmit(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.onSubmitApi();
        } else {
          this.$message.error("请完善信息");
          return false;
        }
      });
    },

    onSubmitApi() {
      this.submitCount = this.submitCount + 1;
      if (this.submitCount > 1) {
        this.$message({
          message: "请勿频繁点击",
          type: "warning",
          duration: 3000,
        });
        return;
      }
      let params = this.ruleForm;
      params.fileList.forEach((item) => {
        if (item.uid) {
          delete item.uid;
        }
        if (item.url) {
          let url = item.url;
          item.path = url.fileUrl;
          item.pdfPath = url.pdfUrl || url.fileUrl;
          delete item.url;
        }
      });
      if (this.lookSubStatus == 0) {
        this.$api.getReportSave(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("上报成功");
            this.goBack();
            this.submitCount = 0;
          } else {
            this.$message.error(res.data.msg);
            this.submitCount = 0;
          }
        });
      } else if (this.lookSubStatus == 3) {
        this.ruleForm.historyList = [];
        var obj = this.ruleForm;
        obj.id = this.lookId;
        this.$api.getReportUpdate(obj).then((res) => {
          if (res.data.code == 0) {
            this.$message.success("编辑成功");
            this.goBack();
            this.submitCount = 0;
          } else {
            this.$message.error(res.data.msg);
            this.submitCount = 0;
          }
        });
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "110px";
      } else {
        this.labelWidth = (150 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

.contain {
  padding: 34px 30px 30px 30px;

  /deep/.el-form-item__label {
    font-size: 16px;
    font-weight: 400;
    color: #606266;
    padding: 0 20px 0 0;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }

  .head-tips {
    display: flex;
    align-items: center;
    width: 50%;
    height: 40px;
    background: #fff8e6;
    border: 1px solid #ffd58f;
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);

    .tips-icon {
      width: 14px;
      height: 14px;
      margin: 0 8px 0 16px;
    }
  }

  .con-header-little {
    width: 80%;
    height: 20px;
    margin: 40px 0 30px 0;
    display: flex;
    align-items: center;
  }

  .con-header-little span {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #3d3e4f;
    flex-shrink: 0;
    margin-right: 30px;
  }

  /deep/.el-divider--horizontal {
    margin: 0;
  }

  .form {
    width: 80%;
    margin-top: 30px;

    /deep/.el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/.el-form-item__label {
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
  }

  .measure-mar {
    margin-bottom: 30px;
  }

  .measure-contain {
    width: 100%;
    min-height: 104px;

    .middle-content {
      border-bottom: 1px solid #e6ebf3;
    }

    .mea-con-tablehead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      font-size: 14px;

      .tablehead-times {
        width: 220px;
        display: flex;
        justify-content: center;
      }
      .tablehead-idea {
        flex: 1;
        width: calc(100% - 240px);
        padding-left: 20px;
        overflow: hidden;
        word-break: break-all;
        display: flex;
        justify-content: center;
      }
    }

    .tablehead-backg {
      height: 48px;
      background: #f1f4f9;
      border-radius: 12px 12px 0px 0px;
      padding: 0;
    }

    .tablehead-times-head {
      font-weight: 400;
      color: #5e6d9b;
    }
  }

  .files-look {
    display: flex;
    align-items: center;

    .file-smallimg {
      width: 12px;
      height: 14px;
      margin-right: 4px;
    }

    .files-button {
      margin-left: 40px;
    }
  }

  .remind {
    // width: 600px;
    display: flex;
    padding: 10px 0 0 0;

    .remind-title {
      width: 110px;
      padding-right: 20px;
      text-align: right;
    }

    .remind-title,
    .remind-content {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: #c6000b;
    }

    .remind-content {
      // margin-left: 12px;
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 120px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  .form-btn-dt {
    margin-top: 43px;
    display: flex;
    padding-left: 120px;

    .goback {
      display: block;
      width: 137px;
      height: 40px;
      background: #ff4444;
      box-shadow: 0px 3px 10px rgba(255, 68, 68, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
}
</style>
